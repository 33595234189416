import React, { Component } from 'react';
import { Breadcrumb, Button, Image } from 'react-bootstrap';
import 'moment/locale/id.js';
import CurrencyFormat from 'react-currency-format';
import ReactToPrint from "react-to-print";
const moment = require('moment');

class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
      is_show: false,
      image_64: '',

    }
  }

  // onChangePotongan = e => {let potongan2 = this.state.tr_data_bayar[0].nominal * this.state.tr_data_bayar[0].potongan / 100
  //                          console.log(this.state.tr_data_bayar[0].potongan)

  //                         let nominal2 = this.state.tr_data_bayar[0].nominal - potongan2;
  //                         this.setState({nominal2)}

  componentDidMount() {
    this.setState({ student_id: localStorage.getItem('DATA_JALUR').split('-')[0] });

    fetch(global.variable.LINK_API + 'student/detail/' + localStorage.getItem('DATA_JALUR').split('-')[0],
      {
        method: 'post',
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          'Authorization': 'Bearer ' + global.cookies.get('access_token'),
        },
        //body: JSON.stringify(data), 
      })
      .then(response => response.json())
      .then(resData => {
        if (resData.message === "Success") {
          this.setState(resData.data[0]);
          this.setState({ is_show: true });

          // let potongan2 = this.state.data_bayar[0].nominal * this.state.data_bayar[0].potongan / 100

          //                 let nominal2 = this.state.data_bayar[0].nominal - potongan2;
          //                    this.setState({hasil:nominal2})
          // let total = this.state.data_bayar[2].nominal + this.state.data_bayar[3].nominal + this.state.data_bayar[4].nominal;
          //             this.setState({kegiatan:total})
          // let akhir = this.state.data_bayar[1].nominal + total;
          //             this.setState({nominalakhir:akhir})
          // let angsur = (this.state.hasil - this.state.data_bayar[0].dp) / this.state.data_bayar[0].termin
          //                this.setState({angsuran:angsur})
        }
      })
  }

  renderTanggal() {
    if (this.state.registration_type_id === 'JT' && this.state.is_reserve === '1') {
      return (<label>Sabtu, 7 Desember 2019 Pukul 12.00.</label>)
    } else {
      if (this.state.registration_type_id === 'JT') {
        return (<label>Sabtu, 7 Desember 2019 Pukul 12.00.</label>)
      } else {
        return (moment().add(5, 'days').format('DD-MM-YYYY'))
      }
    }
  }

  countTotal() {
    let total = 0;
    this.state.data_potongan.map((data) => {
      total += parseInt(data.nominal)
    })
    let DPP = this.state.data_bayar.length > 0 ? parseInt(this.state.data_bayar[0].nominal) : 0;
    return DPP - total;
  }

  countTotalPotongan() {
    let total = 0;
    this.state.data_potongan.map((data) => {
      total += parseInt(data.nominal)
    })
    return total;
  }

  render() {
    let currency = Intl.NumberFormat('en-ID');

    return (
      this.state.is_show ?
        <div id="divPrint" style={{ width: 810, border: '0px solid black' }}>
          <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', textAlign: 'center', }}>
            <img src={require("../assets/images/kop_surat.png")} alt="kop" style={{ width: 700 }} />
          </div>
          {/* <div style={{alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize:20, marginTop:5, textAlign: 'center', }}>
              <label style={{textDecorationLine: 'underline'}}>PERNYATAAN KESANGGUPAN KEUANGAN SEKOLAH</label>
            </div> */}

          <div className="rowHorizontal">
            <div style={{ fontSize: 16, marginLeft: 50, marginTop: 5 }}>
              <label>PENERIMAAN PESERTA DIDIK BARU {this.state.tahun_ajaran}</label>
              <br></br>
              <label>FORMULIR PENENTUAN PEMBAYARAN LUNAS/ANGSURAN</label>
            </div>
            <div style={{ marginLeft: 40, width: 200, height: 40, border: '1px solid black' }}>
              <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize: 16, marginTop: 10, textAlign: 'center', textDecorationLine: 'underline' }}>
                <label>NO DAFT : {this.state.year + '-' + this.state.registration_type_id + '-' + this.state.registration_number}</label>
              </div>
              <div style={{ marginTop: 10, width: 200, height: 40, border: '1px solid black' }}>
                <div style={{ alignItems: 'center', alignContent: 'center', justifyContent: 'center', fontSize: 16, marginTop: 10, textAlign: 'center', textDecorationLine: 'underline' }}>
                  <label>NO VA : {this.state.student_id}</label>
                </div>
              </div>
            </div>
          </div>
          <div>
            <label></label>

            <table style={{ width: '80%', marginLeft: 50, padding: 5, fontSize: 14, border: 'none', borderCollapse: 'collapse' }}>
              <tr>
                <td style={{ width: '20%', border: 'none' }}>UNIT</td>
                <td style={{ border: 'none' }}>: {this.state.nama_sekolah}</td>
              </tr>
              <tr>
                <td style={{ border: 'none' }}>NAMA</td>
                <td style={{ border: 'none' }}>: {this.state.fullname}</td>
              </tr>
              <tr>
                <td style={{ border: 'none', verticalAlign: 'top' }}> ALAMAT</td>
                <td style={{ border: 'none' }}>: {this.state.address}</td>
              </tr>
              <tr>
                <td style={{ border: 'none' }}>NO. TELEPON</td>
                <td style={{ border: 'none' }}>: {this.state.hp}</td>
              </tr>
            </table>

            {/* </div>
              <div style={{ margin:5, marginLeft:50, marginTop:5, fontSize:14}}>
                <label style={{width:175}}>UNIT</label>
                <label>: {this.state.nama_sekolah}</label>
                <br></br>
                <label style={{width:175}}>NAMA</label>
                <label>: {this.state.fullname}</label>
                <br></br>
                <label style={{width:175}}>ALAMAT</label>
                <label>: {this.state.address}</label>
                <br></br>
                <label style={{width:175}}>NO. TELEPON </label>
                <label>: {this.state.hp}</label>
                <br></br>
              </div>
            <div> */}
            <div style={{ margin: 5, marginLeft: 50, marginTop: 10, fontSize: 14 }}>
              <label style={{ width: 175 }}>JENIS PEMBAYARAN </label>
            </div>
          </div>
          <div >

            <table style={{ width: '85%', marginLeft: 50, paddingBlock: '50px', fontSize: 14 }}>
              <tr>
                <th>No</th>
                <th>Jenis Pembayaran</th>
                <th>Jumlah Pembayaran (Rp)</th>
              </tr>
              <tr>
                <td>1</td>
                <td style={{ fontWeight: 'bold' }}>Dana Pengembangan Pendidikan</td>
                <td>Rp. {currency.format(this.state.data_bayar.length > 0 ? this.state.data_bayar[0].nominal : 0)}</td>
              </tr>
              <tr>
                <td></td>
                <td>a. Potongan Early Bird</td>
                <td>Rp. {currency.format(this.state.data_potongan[0].nominal)}</td>
              </tr>
              <tr>
                <td></td>
                <td>b. Potongan Prestasi</td>
                <td>Rp. {currency.format(this.state.data_potongan.length > 1 ? this.state.data_potongan[1].nominal : 0)}</td>
              </tr>
              <tr>
                <td></td>
                <td>c. Potongan Langsung Lunas</td>
                <td>Rp. {currency.format(this.state.data_potongan.length > 2 ? this.state.data_potongan[2].nominal : 0)}</td>
              </tr>
              <tr>
                <td></td>
                <td>d. Potongan Kakak Adik</td>
                <td>Rp. {currency.format(this.state.data_potongan.length > 3 ? this.state.data_potongan[3].nominal : 0)}</td>
              </tr>
              <tr>
                <td></td>
                <td>e. Potongan DPP Tambahan</td>
                <td>Rp. {this.state.data_potongan.length > 4 ? currency.format(this.state.data_potongan[4].nominal) : 0}</td>
              </tr>
              <tr>
                <td></td>
                <td>Jumlah Potongan</td>
                <td>Rp. {currency.format(this.countTotalPotongan())}</td>
              </tr>
              <tr>
                <td></td>
                <td>Jumlah DPP</td>
                <td>Rp. {currency.format(this.countTotal())}</td>
              </tr>
              <tr>
                <td>2</td>
                <td style={{ fontWeight: 'bold' }}>Uang Sekolah</td>
                <td>Rp. {currency.format(this.state.data_bayar.length > 1 ? this.state.data_bayar[1].nominal : 0)}</td>
              </tr>
              <tr>
                <td>3</td>
                <td style={{ fontWeight: 'bold' }}>Uang Kegiatan</td>
                <td>Rp. {currency.format(this.state.data_bayar.length > 2 ? this.state.data_bayar[2].nominal : 0)}</td>
              </tr>
            </table>
            <div>
              <div style={{ marginLeft: 50, marginTop: 10, fontSize: 14 }}>
                <label style={{ width: 175 }}>JADWAL PEMBAYARAN </label>
              </div>
            </div>
            <table className="table-custom" style={{ width: '85%', marginLeft: 50, padding: 5, fontSize: 14 }}>
              <tr>
                <th style={{ fontWeight: 'bold' }}>SISTEM PEMBAYARAN</th>
                <th style={{ fontWeight: 'bold' }}>TANGGAL TAGIHAN</th>
                <th style={{ fontWeight: 'bold' }}>JUMLAH TAGIHAN</th>
                <th style={{ fontWeight: 'bold' }}>JUMLAH UANG YANG DIBAYARKAN</th>
                <th style={{ fontWeight: 'bold' }}>PARAF PETUGAS</th>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>LUNAS 100% DPP</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td style={{ fontWeight: 'bold' }}>DP 25% DPP</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 1</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 2</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 3</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 4</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 5</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 6</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 7</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 8</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 9</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 10</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 11</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
              <tr>
                <td>Angsuran 12</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </table>
            <table style={{ width: '70%', marginLeft: 50, padding: 5, fontSize: 14, border: 'none', borderCollapse: 'collapse' }}>
              <tr>
                <td style={{ width: '10%', border: 'none', fontSize: 14, fontWeight: 'bold' }}>Note :</td>
                <td style={{ border: 'none', fontSize: 14, fontWeight: 'bold' }}>- Pembayaran paling lambat tanggal 10 setiap bulannya</td>
              </tr>
              <tr>
                <td style={{ border: 'none' }}> </td>
                <td style={{ border: 'none', fontSize: 14, fontWeight: 'bold' }}>- Uang Sekolah dan Uang Kegiatan dibayarkan mulai tanggal 1 Juli</td>
              </tr>
            </table>
            {/* <label style={{ margin: 0, marginLeft: 55, fontSize: 14 }}>Note: - Pembayaran paling lambat tanggal 10 setiap bulannya</label>
            <label style={{ margin: 0, marginLeft: 55, fontSize: 14 }}>      - Uang Sekolah dan Uang Kegiatan dibayarkan mulai tanggal 1 Juli</label> */}

          </div>
          <div className="rowHorizontal">
            <div style={{ margin: 0, marginTop: -20, marginLeft: 575, fontSize: 14 }}>
              {/* <label>Ditetapkan di:</label>
                <br></br> */}
              <br></br>
              <br></br>
              <label>{this.state.kota}, {moment().format('DD-MM-YYYY')}</label>
            </div>
          </div>

          <div className="rowHorizontal">
            <div style={{ width: 250, margin: 0, marginLeft: 55, fontSize: 14 }}>
              <label>Disetujui</label><br></br>
              {/* <label>Pewawancara</label> */}
            </div>
            <div style={{ margin: 0, marginLeft: 270, fontSize: 14 }}>
              <label>Panitia PPDB</label>
              {/* <label>(Orang Tua / Wali Murid)</label> */}
            </div>
          </div>

          <div className="rowHorizontal">
            <div style={{ width: 200, margin: 40, marginLeft: 50 }}>
            </div>
          </div>

          <div className="rowHorizontal">
            <div style={{ width: 150, marginLeft: 50, fontSize: 11, marginTop: -10 }}>
              <label style={{ textDecorationLine: 'underline' }}>(_________________________________)</label>
            </div>
            <div style={{ marginLeft: 370, fontSize: 11, marginTop: -10 }}>
              <label style={{ textDecorationLine: 'underline' }}>(_________________________________)</label>
            </div>
          </div>
        </div>
        : ''
    );
  }
}

class Cetak extends Component {

  constructor(props) {
    super(props);

    this.state = {
      student_id: '',
    }
  }

  handleSubmit = () => {
    global.swal({
      text: 'Apakah Surat Perjanjian sudah di PRINT dan Anda yakin akan disubmit?',
      closeOnEsc: false,
      closeOnClickOutside: false,
      buttons: [
        'Cancel',
        {
          text: 'Submit',
          closeModal: false,
        }
      ],
    })
      .then((confirm) => {
        let errorMessage = { code: 403, message: 'cancel' };
        if (!confirm) throw errorMessage;
        const data = {
          student_id: localStorage.getItem('DATA_JALUR').split('-')[0],
          flow_id: 8,
        }
        return fetch(global.variable.LINK_API + 'wawancara/update_flow',
          {
            method: 'post',
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
              'Authorization': 'Bearer ' + global.cookies.get('access_token'),
            },
            body: JSON.stringify(data),
          })
          .then(response => response.json())
          .then(resData => {
            if (resData.message === "Success") {
              global.swal("Success", 'Berhasil Submit', "success")
                .then((value) => {
                  window.location.href = "/wawancara";
                });
            } else {
              global.swal("Error", resData.error, "info")
              global.swal.stopLoading();
            }
          })
      })
      .catch(err => {
        console.log(err)
        if (err) {
          if (err.code !== 403) {
            global.swal("Error", "Koneksi ke server gagal.", "info")
          }
        } else {
          global.swal.stopLoading();
          global.swal.close();
        }
      });
  }

  buildBtnSubmit() {
    let data = '';
    let flow_id = localStorage.getItem('flow_id')
    if (parseInt(flow_id) === 5) {
      data = (
        <div className='rowHorizontal'>
          <Button onClick={this.handleSubmit} block style={{ marginBottom: 10 }} >Submit</Button>
        </div>);
    }
    return data;
  }

  render() {
    return (
      <div className="content-wrapper">
        <section className="content-header">
          <div className="row">
            <div className="col-md-12">
              <div className="box">
                <div className="divContainter">
                  <Breadcrumb>
                    <Breadcrumb.Item href="/home">Home</Breadcrumb.Item>
                    <Breadcrumb.Item active>Cetak SK Finansial</Breadcrumb.Item>
                  </Breadcrumb>

                  <div>
                    <ReactToPrint
                      trigger={() => <Button style={{ marginBottom: 10 }} block>Print</Button>}
                      content={() => this.componentRef}
                    />
                    <ComponentToPrint ref={el => (this.componentRef = el)} />
                  </div>
                  {this.buildBtnSubmit()}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

    );
  }
}

export default Cetak;